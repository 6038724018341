import React, { useState } from 'react';

import { Head } from '../components';
import { VkwFullLayout } from '../library';
import { MyNextPage } from '../types/next';
import { getEnvUrls } from '../util/helpers';
import { ChargeAtlas } from '../views';

const IndexPage: MyNextPage = () => {
  const [url] = useState<string>(() => {
    if (typeof window !== 'undefined') {
      return window.location.href;
    }

    return `${getEnvUrls().baseUrl}`;
  });

  return (
    <>
      <Head pageTranslationKey="ChargeAtlas" description url={url} />

      <VkwFullLayout>
        <ChargeAtlas />
      </VkwFullLayout>
    </>
  );
};

export default IndexPage;
